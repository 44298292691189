<template>
  <div class="aPhoneInput field">
    <label  :for="fieldname">{{label}} <span v-if="isRequired" class="req">*</span></label>
    <input
    ref="phone"
    type="tel"
    v-model="phoneNumber"
    :placeholder="placeholder"
    :disabled="disabled"
    :id="fieldname"
    :class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
    @countrychange="sendInternationalFormat">
    <div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
    <div class="helper">{{helper}}</div>
  </div>
</template>

<!-- ================================================================================== -->

<script>
 import 'intl-tel-input/build/css/intlTelInput.css';
 import intlTelInput from 'intl-tel-input';
 import FormFields from "../mixins/FormFields";
 import {debounce} from "lodash";
 import config from "../config";
 import customLog from "../mixins/CustomLog";
 export default {
  name: "PhoneInput",
  mixins: [FormFields, customLog],
  props: {
    fieldname: {
      type: String,
      required: true
    },
    modelValue: {
      required: true,
      type: String
    },
    initialCountry: {
      required: false,
      type: String,
    default: 'NL'
    },
    preferredCountries: {
      required: false,
      type: Array,
    default: () => ["nl"]
    },
    countries: {
      required: false,
      type: Array,
    default: () => ['nl']
    },
    label : {
      type: String,
      required : true
    },
    receivedNumber: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      phonejs: '',
      config: config,
      disabled: false,
      errorMessage: null,
    }
  },


  methods: {

    sendInternationalFormat() {
      let phoneToEmit = this.phonejs.getNumber();
      if (phoneToEmit != ""){
        this.$emit('internationalNumber', phoneToEmit); 
      }else{
        this.log("sendInternationalFormat", 'function');
        this.log("phonejs.getNumber() returns an empty value!", 'alert');
      }

    },

    lockThisField() {
      this.disabled = true;
    },

    validateMyself() {
      this.setLoading();
      /* ! This won't work if not configured in the LEAD, returning a non-managed error (see comment below) */
      let data = {
        [this.fieldname]: this.phonejs.getNumber()
      }
      this.axios.post(`/lead/${config.idlead}/data/${this.fieldname}/validate`, data)
      .then(() => {
        this.giveSuccess();
        this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
        this.setNotLoading();
      }).catch(error => {
        if(error.response.status === 503){
          this.setNotLoading();
          this.$emit('maintenanceDetected');
        }else{
          this.giveError();
          this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
          this.errorMessage = error.response?.data?.errors[this.fieldname][0];
          this.setNotLoading();
        }
      })
    }
  },
  computed: {
    phoneNumber: {
      get: function () {
        return this.modelValue;
      },
      set: function (newval) {
        this.validateMyselfDebounced();
        this.$emit('update:modelValue', newval);
        this.sendInternationalFormat();
      }
    },
  },
  mounted() {
    this.phonejs = intlTelInput(this.$refs.phone, {
      initialCountry: this.initialCountry,
      autoHideDialCode: false,
      utilsScript: require('intl-tel-input/build/js/utils'),
      onlyCountries: this.countries,
      preferredCountries: this.preferredCountries,
      nationalMode: true,
      customContainer: "fullwidth",
    });

    this.validateMyselfDebounced = debounce(this.validateMyself, 1000);
      // run validation immediately when the value is not null or empty
    if(this.phoneNumber) {
      this.validateMyselfDebounced();
      this.sendInternationalFormat();
    }

      // If a phone number is received from From.vue, display it.
    if(this.receivedNumber && this.receivedNumber.length > 0){
      this.phoneNumber = this.receivedNumber;
      this.lockThisField();
    }
  },
}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
