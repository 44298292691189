export default {
	data () {
		return {

			/* For CLIENT in GDPR, see the translation files */


			/* Page to load when the form is ready and user first connect */
			/* List of usual first pages: 'landing', 'instructions', 'form1'… */
			firstPageOfTheForm: 'landing', /* 'landing', */

			/* For P&G */
			isPnGAction: true, // allow OneTrust tag (App.vue)

			/* SCRIPTS */
			thereIsFloodLightScriptInThisAction: false,


			/* BREADCRUMBS */
			displayBreadCrumbs: false,
			listOfCrumbsForThisAction: ['registration', 'store', 'form', 'survey', 'cashback'],
			 /* INFO Possibles values : 'info', 'registration', 'store', 'form', 'survey', 'summary', 'cashback' */
			


			/* ? Allow a coupon in the mail ?*/
			thereIsASurveyInThisAction: false, 

			/* Don't forget to check on mixins "ga" and "cf" for google analytics and cookie first */

			/* List of fields that must be valid for a specific button to unlock */
			/* ! Country must not be required when the google API put the value in the field, as it is not validated by action api then. */
			/* TODO: find a way to make action api validate that field */

			mustBeValidForPage: {
				'btn_cashback_on_form1' : ['firstName', 'lastName', 'email', 'IBAN', 'idTitle'],
				'btn_cashback_on_form2' : ['file', 'conditions', 'idLeadTMD1','optin2'],
				'btn_cashback_on_survey': [],
			},

			// ! Maybe I can compose this programmatically ;)
			allRequiredFields: ['firstName', 'lastName', 'email', 'IBAN', 'idTitle', 'file', 'conditions', 'idLeadTMD1','optin2'],

		}
	},
}
