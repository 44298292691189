import config from "../config";

export default {
	data () {
		return {
			callConfigs: {

				getFingerprint: {
					title: 'GET getFingerprint',
					url: `/fingerprint/${config.idlead}`,
					cbSuccess: this.whatIfIGotTheFingerPrint,
					cbError: this.whatIfIDontGetTheFingerprint
				},
				getLocalizedData : {
					title: 'GET localizing data',
					url: `/dropdown/${config.idlead}/languages-code`,
					cbSuccess: this.localizingDataFound, 
					cbError: this.localizingDataNotFound
				},
				getLead : {                    
					title: 'GET Lead',
					url: `/leads/${config.idlead}`,
					cbSuccess: this.whatIfIGetTheLead, 
					cbError: this.whatIfIDontGetTheLead,
				},
				getProductsIds: {
					title: 'GET Products IDs',
					url: `/dropdown/${config.idlead}/popitems`,
					cbSuccess: this.whatIfIFoundProductsIds, 
					cbError: this.whatIfICantFoundProductId
				},
				getParticipationOwnerData: {
					title: 'GET participationOwner',
					url: `/participationOwner/participation/`,
					cbSuccess: this.whatIfIGetTheParticipationOwnerData,
					cbError: this.whatIfIDontGetTheParticipationOwnerData
				},
				getOwnerData: {
					title: 'GET OwnerData',
					url: `/participationOwner/`,
					cbSuccess: this.whatIfIGetTheOwnerData, 
					cbError: this.whatIfIDontGetTheOwnerData
				},
				getMetasIds: {
					title: 'GET Fields',
					url: `/lead/${config.idlead}/fields`,
					cbSuccess: this.whatIfIGetTheFields, 
					cbError: this.whatIfIDontGetTheFields
				},
				getCountries: {
					title: "GET allowed Countries",
					url: `/dropdown/${config.idlead}/countries-allowed`,
					cbSuccess: this.whatIfIGetTheCountries,
					cbError: this.whatIfICantGetTheCountries
				},
				getRetailers: {
					title: "GET Retailers list",
					url: `/dropdown/${config.idlead}/retailers`,
					cbSuccess: this.whatIfIGetTheRetailersList,
					cbError: this.whatIfIDontGetTheRetailersList
				},
				postParticipationOwner: {
					title: 'POST Participation Owner',
					url: `/participationOwner`,
					cbSuccess: this.successInCreateParticipationOwner,
					cbError: this.failedInCreateParticipationOwner
				},
				postParticipation: {
					title: 'POST Participation',
					url: `participation`,
					cbSuccess: this.participationSuccessful,
					cbError: this.participationFailed,
				}

			}
		}
	},
	methods: {

		/* One function to call them all */
		getFromAxios(call, arg=''){
			this.log(`${call.title}`, 'api');
			this.log(call);
			if(arg.length>0){this.log(`Argument received to add at the end of API call url: ${arg}`, 'api');}
			this.axios.get(call.url + arg)
			.then(res => {
				this.log(`API call "${call.title}" is a success`, 'success');
				this.log(res.data);
				call.cbSuccess(res.data);
			})
			.catch(error => {
				this.log(`Axios "${call.title}" function`, 'error');
				this.log(error);
				call.cbError(error);
			})
		},

		postToAxios(call, data){
			this.log(`Sending this information to actionAPI`);
			this.log(data);
			this.axios.post(call.url, data)
			.then(res => {
				this.log(`Success in posting ${call.title} data`,'success');
				this.log(res);	
				call.cbSuccess(res);
			})
			.catch(error => {
				this.log(`Failed attempt to post for ${call.title}`, 'alert');
				console.log(error);
				call.cbError(error);
			})
		},



	},
}