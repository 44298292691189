/* Cheat.js */
/* "CHEATS" works only if (this.allowCheat) */

export default {
	data() {
		return {
			isDevNavVisible: false,
			keyChain : [],
			cheatPanelHidden: false,
		}
	},
	methods: {

		loadFor5Sec(){
			/* Run the loader screen for 5 seconds */
			this.pendingParticipationSent = true;
			let self = this;
			setTimeout(function(){
				self.pendingParticipationSent = false;
			}, 3000);
		},

		unlockButtons(){
			this.log("Unlock button");
            // select all button, remove attribute "disabled"
            let listButtons = document.querySelectorAll("button");
            listButtons.forEach(button => {
            	button.disabled = false;
            })
        },

        displayFieldsValues(){
        	this.log("FieldsValues", "sep");
        	this.log(this.fieldsValues);
        },

        displayFieldsValidity(){
        	this.log("All fields current validity", "sep");
        	/* ? I should add the "required" status */
        	/* Or fuse this with the "display fieldsValues, and display values for each field */
        	for (let item in this.fieldsValidity){
        		this.log(`${item}: ${this.fieldsValidity[item]}`, this.fieldsValidity[item]?'green': 'red');
        	}
        },

        toggleCheatPanel(){
        	this.cheatPanelHidden = !this.cheatPanelHidden;
        },
        getAdminFromUrl(){
        	let urlParams = new URLSearchParams(window.location.search);
        	if(urlParams.has('cheat')){
        		if(urlParams.get('cheat')==='on'){
        			this.isDevNavVisible = true;
        			this.log(`CHEAT mode activated`, 'title');
        		}
        	}
        },
        getAdminFromKeys(e){
        	this.keyChain.push(e.keyCode);
        	if (this.keyChain.length > 8) {
        		this.keyChain.shift();
        	}
        	if (JSON.stringify(this.keyChain) === JSON.stringify([67,72,69,65,84,78,65,86]) ){
        		this.isDevNavVisible = !this.isDevNavVisible;
        	}
        },

        showDataToBeSent(){
        	this.composeData()
        },
    },
    mounted(){
    // Cheat by keyboard
    window.addEventListener('keydown', this.getAdminFromKeys);   
    this.getAdminFromUrl();
},
}